.container {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    332deg,
    rgba(0, 0, 0, 1) 83%,
    rgba(10, 10, 8, 1) 85%,
    rgba(15, 15, 13, 1) 86%,
    rgba(22, 27, 29, 1) 89%
  );
  width: 100%;
  height: 100vh;
  margin-top: 4rem;
  padding-top: 4rem;
}

.heading {
  color: white;
  font-weight: 500;
  font-size: 3rem;
  font-family: "Robota", sans-serif;
}
.cardsContainer {
  display: flex;
  padding: 12%;
  gap: 6rem;
}
.cardsContainer h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 30px;
}
.links {
  text-align: start;
}
.links p:first-of-type {
  color: #0086ff;
  margin-bottom: 0.7rem;
}
@media only screen and (max-width: 480px) {
  .cardsContainer {
    margin-top: 3rem;
    padding: 3%;
    flex-direction: column;
    background-size: 100vw 40vh;
    gap: 1rem;
  }

  .heading {
    font-size: 30px;
  }

  .container p {
    margin-top: 1rem;
    font-size: 15px;
    width: 100%;
  }
  .buttons {
    flex-direction: column;
  }
}
