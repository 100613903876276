.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    335deg,
    rgba(0, 0, 0, 1) 29%,
    rgba(9, 12, 12, 1) 40%,
    rgba(22, 27, 29, 1) 55%
  );
  /* height: 600px; */
  width: 49.5%;
  border-radius: 15px;
  padding: 5%;
}

.container div {
  width: 100%;
  padding: 0 0 5rem 0;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1rem;
  align-items: flex-start;
}

.container div p {
  width: 90%;
}

.container p:last-of-type {
  color: #0086ff;
  margin-bottom: 0.5rem;
  align-self: baseline;
}

.container h6 {
  color: #7c8c92;
  font-weight: 500;
  font-size: 12px;
}
@media only screen and (max-width: 480px) {
  .container {
    width: 100%;

    margin-bottom: 10px;
  }
  .container div {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;

    align-items: flex-start;
  }
}
