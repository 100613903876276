.container {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    332deg,
    rgba(0, 0, 0, 1) 83%,
    rgba(10, 10, 8, 1) 85%,
    rgba(15, 15, 13, 1) 86%,
    rgba(22, 27, 29, 1) 89%
  );
  width: 100%;
  height: 100vh;
  margin-top: 4rem;
  padding-top: 4rem;
}

.heading {
  color: white;
  font-weight: 500;
  font-size: 3rem;
  font-family: "Robota", sans-serif;
}
.cardsContainer {
  display: flex;
  padding: 12%;
  gap: 6rem;
}
.cardsContainer h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
}
.links {
  text-align: start;
}
.links p {
  color: #0086ff;
  margin-bottom: 0.7rem;
}
.links p span {
  color: white;
  margin-bottom: 0.7rem;
}
.summary {
  display: flex;
  background-color: #161b1d;
  flex-direction: column;
  gap: 5px;
  margin: 0 15%;

  border-radius: 15px;
}
.summary h1 {
  color: #0086ff;
  margin-bottom: 3rem;
}
.summary p {
  text-align: center;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 480px) {
  .cardsContainer {
    margin-top: 3rem;
    padding: 3%;
    flex-direction: column;
    background-size: 100vw 40vh;
    gap: 1rem;
  }

  .summary {
    margin-top: 3rem;
    margin: 3%;
    padding: 3%;
    flex-direction: column;
    background-size: 100vw 40vh;
    gap: 1rem;
  }
  .heading {
    font-size: 30px;
  }

  .container p {
    margin-top: 1rem;
    font-size: 15px;
    width: 100%;
  }
  .buttons {
    flex-direction: column;
  }
}
