.header {
  display: flex;
  height: 4rem;
  background: rgba(22, 29, 28, 0.99);
  border-bottom: 0.1px solid rgb(136, 137, 138);
  width: 100%;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  font-weight: 400;
}
.image {
  height: 90%;
}
.menu {
  list-style: none;
  display: flex;

  align-items: center;
  gap: 15px;
}

.navItem {
  text-decoration: none;
  color: white;
}
.menu > li:hover {
  cursor: pointer;
}
.selected {
  background-color: #2d2d2b;
  padding: 5px;
  border-radius: 5px;
}
.dappButton {
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
}

.social {
  font-family: Social;
  text-decoration: none;
  margin-right: 0.5rem;
  color: white;
  font-size: 32px;
}
@media only screen and (max-width: 480px) {
  .menu,
  .social {
    display: none;
  }
}
