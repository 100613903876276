.container {
  padding-top: 4rem;
  height: 100vh;
  background-image: linear-gradient(
      180deg,
      rgba(43, 42, 42, 0.1),
      rgba(0, 0, 0, 0.75)
    ),
    url("../assets/bg.png");
  padding: 0 20%;

  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: 100vw 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.heading {
  font-family: "Roboto", sans-serif;
  font-size: 4em;
  font-style: normal;
}

.container p {
  width: 50%;
  align-self: center;
}
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  gap: 1rem;
}
.buttons > a > button {
  background: rgb(24, 204, 204);
  background: linear-gradient(
    61deg,
    rgba(24, 204, 204, 1) 0%,
    rgba(0, 134, 255, 1) 59%,
    rgba(0, 134, 255, 1) 100%
  );
  padding: 1.5rem;
  border-radius: 8px;
  border: none;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  cursor: pointer;
}
.buttons > a {
  text-decoration: none;
  color: white;
}
.buttons > a > button:hover {
  padding: 1.6rem;
}

@media only screen and (max-width: 480px) {
  .container {
    padding: 2%;

    background-size: 100vw 40vh;
  }

  .heading {
    font-size: 30px;
  }

  .container p {
    margin-top: 1rem;
    font-size: 15px;
    width: 100%;
  }
  .buttons {
    flex-direction: column;
  }
}
