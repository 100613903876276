.container {
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 3rem;
  margin-top: 10rem;
  flex-wrap: nowrap;
  justify-content: space-between;
}
