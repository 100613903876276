.container {
  background-color: #161b1d;
  width: 49%;
  border-radius: 15px;
  /* height: 300px; */
  gap: 6rem;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
}
@media only screen and (max-width: 480px) {
  .container {
    padding: 6%;
    flex-direction: column;
    width: 100%;
  }
}
