.container {
  padding: 0 15%;
  /* display: flex; */
  flex-direction: column;
  gap: 5px;
  margin-bottom: 3rem;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 10rem;
}

.title {
  font-size: 15px;
  color: #0086ff;
}
.heading {
  font-size: 3rem;
  font-weight: 400;
}
.tagline {
  color: #7c8c92;
  margin-bottom: 5rem;
}
.VerticalCards {
  display: flex;
  margin: 10px 0;
  width: 100%;
  gap: 1%;
}

@media only screen and (max-width: 480px) {
  .container {
    padding: 0 5%;
  }
  .VerticalCards {
    flex-direction: column;
  }
  .contract {
    font-size: xx-small;
  }
}
