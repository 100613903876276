.menu {
  display: none;
}
.hidden {
  display: none;
}

@media only screen and (max-width: 480px) {
  .menu {
    display: flex;
    flex-direction: column;
    left: 0;
    top: 2rem;
    right: 10%;
    width: 100vw;
    position: fixed;
    list-style: none;
  }
  .hidden {
    display: block;
  }
  .home {
    font-family: "Roboto", sans-serif;
    margin: 0 3%;
    padding-right: 1rem;
    font-size: 12px;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #202527;
    border-bottom: 1px solid #0086ff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .homeButton {
    border-bottom: none !important;
  }

  .navItem {
    text-decoration: none;
    color: white;
    font-family: "Roboto", sans-serif;
    margin: 0 3%;
    height: 4rem;
    padding-left: 1rem;
    font-size: 12px;
    font-weight: 300;
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: #202527;
    border-bottom: 2px solid #0086ff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .socials {
    display: flex;
    font-family: Social;
    font-size: 2rem;
    justify-content: center;
    gap: 1rem;
  }
  .socials a {
    text-decoration: none;
    color: white;
  }
}
