.container {
  display: flex;
  justify-content: space-between;
  background-color: #161b1d;
  height: 500px;
  border-radius: 15px;
  margin-top: 10px;
}

.container div {
  width: 50%;
  padding: 10% 5% 5% 5%;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1rem;
  align-items: flex-start;
}
.container div img {
  width: 70%;
  height: 100%;
}
container:nth-child(1) {
  border: 2px solid red;
  padding: 0;
  margin: 0;
}
.container div p {
  width: 90%;
}
.container p:first-of-type {
  color: #0086ff;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 480px) {
  .container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-size: 100vw 40vh; */
  }

  .container div {
    width: 100%;

    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 1rem;
    align-items: center;
  }
}
